export const lang = {
   swap1: 'Exchange',
   swap2: 'Trade tokens in an instant',
   swap3: 'From',
   swap4: 'Balance: ',
   swap5: 'token',
   swap6: 'MAX',
   swap7: 'Price',
   swap8: 'Slippage Tolerance',
   swap9: 'Connect Wallet',
   swap10: 'Enter an amount',
   swap11: 'insufficient balance',
   swap12: 'Insufficient liquidity for this trade',
   swap13: 'Select a token',
   swap14: 'Approve',
   swap15: 'Wrap',
   swap16: 'Unwrap',
   swap17: 'swap',
   swap18: 'Transaction receipt',
   swap19: 'Waiting for confirmation',
   swap20: 'Will',
   swap21: 'Exchange for',
   swap22: 'Confirm this transaction in your wallet',
   swap23: 'Submitted transaction',
   swap24: 'View on BSC',
   swap25: 'Dismiss',
   swap26: 'Transaction rejected',
   swap27: 'Confirm Swap',
   swap28: 'If the output is a valuation, you will get at least {inputOutMin} {symbol}, Or the deal will be withdrawn',
   swap29: 'Enter as valuation, you will sell the most {inputInMax} {symbol}, Or the deal will be withdrawn',
   swap30: 'Minimum received',
   swap31: 'Maximum sales',
   swap32: 'To',
   swap33: 'Manage tokens',
   swap34: 'Import token',
   swap35: 'Import',
   swap36: 'Manage tokens',
   swap37: 'Custom token',
   swap38: 'Clear all',
   swap39: 'Search name or paste address',
   swap40: 'Settings',
   swap41: 'Default transaction speed(GWEI)',
   swap42: 'Standard',
   swap43: 'Fast',
   swap44: 'Instant',
   swap46: 'Enter a vaild slippage percentage',
   swap47: 'Your transaction may fail',
   swap48: 'Your transaction may be frontrun',
   swap49: 'Tx deadline (mins)',
   swap50: 'estimated',
   swap51: 'Disable Multihops',
   swap52: 'Recent Transactions',
   swap53: 'No recent transactions',
   swap54: 'Clear All',
   swap55: 'Not connected',
   swap56: 'Join The Board',
   swap57: 'Donates',
   swap58: 'Rights',
   swap59: 'List Of Board Address',
   swap60: 'Referrals',
   swap61: 'Copy',
   swap62: 'My DAPP Referrer:',
   swap63: 'Referrals Address',
   swap64: 'Something went wrong. Please try again later',
   swap65: 'not open',
   swap66: 'harvest',
   swap67: 'Extraction succeeded',
   swap68: 'Donation in progress, please wait',
   swap69: 'Donated',
   swap70: 'Non-Whitelist',
   swap71: 'Failed',
   swap72: 'Insufficient Balance',
   swap73: 'Waiting',
   swap74: 'privilege grant failed',
   swap75: 'Donation in progress, please wait',
   swap76: 'Failed',
   swap77: 'Swap',
   swap78: 'Liquidity',
   swap79: 'Member',
   swap80: 'Referrals',
   swap81: 'Reward Pool',
   swap82: 'Cross chain bridge',
   swap83: 'Pool',
   swap84: 'Farm',
   swap85: 'Home',
   swap86: 'Markets',
   swap87: 'Swap',
   swap88: 'Swap',
   swap89: 'Earn',
   swap90: 'Confirm',
   swap91: 'Price Impact',
   swap92: 'Minimum received',
   swap93: 'Liquidity Provider Fee',
   swap94: 'Add Liquidity',
   swap95: 'Add liquidity to receive LP tokens',
   swap96: 'Share of Pool',
   swap97: 'Prices and pool share',
   swap98: 'Your Liquidity',
   swap99: 'Remove liquidity to receive tokens back',
   swap100: 'No liquidity found.',
   swap101: 'Dont see a pool you joined?',
   swap102: 'Find other LP tokens',
   swap103: 'Pooled',
   swap104: 'Remove',
   swap105: 'Supply',
   swap106: 'Import Pool',
   swap107: 'Import an existing pool',
   swap108: 'Select a token to find your liquidity.',
   swap109: 'You don’t have liquidity in this pool yet.',
   swap110: 'LP tokens in your wallet',
   swap111: 'To receive',
   swap112: 'and',
   swap113: 'Amount',
   swap114: 'YOU WILL RECEIVE',
   swap115: 'Output is estimated. If the price changes by more than {slippage}% your transaction will revert.',
   swap116: 'Burned',
   swap117: 'Deposited',
   swap118: 'Confirm Supply',
   swap119: 'Rates',
   swap120: 'You are the first liquidity provider.',
   swap121: 'The ratio of tokens you add will set the price of this pool.',
   swap122: 'Once you are happy with the rate click supply to review.',
   swap123: 'Removing ',
   swap124: 'Supplying',
   swap125: 'Confirm supply',
   swap126: 'White Paper',
   swap127: 'Audit',
   swap128: 'Notice',
   swap129: 'Tips',
   swap130: 'PHO 1:1 exchange',
   swap131: 'More',
   swap132: 'Loading',
   swap133: 'Information',
   swap134: 'Donation quota is full',
   swap135: 'MY PHO',
   swap136: 'PHO in Wallet',
   swap137: 'PHO to Harvest',
   swap138: 'PHO Price',
   swap139: 'PHO Total',
   swap140: 'PHO Market capitalization',
   swap141: 'PHO INFO',
   swap142: 'Earned',
   swap143: 'STAKED',
   swap144: 'Personal TVL',
   swap145: 'Community TVL',
   swap146: 'Harvest',
   swap147: 'Total reward ',
   swap148: 'Reward today',
   swap149: 'LP Maket Makers address',
   swap150: 'My invitation link',
   swap151: 'Maximum computing power area',
   swap152: 'Other computing power areas',
   swap153: 'My Friends Referrer',
   swap154: 'Cancel',
   swap155: 'Modify Referrer',
   swap156: 'Invitee cannot be empty',
   swap157: 'Modified Successfully',
   swap158: 'STAKED LP token',
   swap159: 'REDEEM LP token',
   swap160: 'REDEEM',
   swap161: 'Obtain',
   swap162: 'No income at present',
   swap163: 'Route',
   swap164: 'Audit institution',
   swap165: 'Cooperative partner',
   swap166: 'Issue',
   swap167: 'Activity',
   swap168: 'Single pool',
   swap169: 'LP pool',
   swap170: 'Congress',
   swap171: 'Card',
   swap172: 'Blind box',
   swap173: 'Game',
   swap174: 'The total amount allocated by the stake pool',
   swap175: 'Amount to be claimed',
   swap176: 'I have got the amount',
   swap177: 'Extraction',
   swap178: 'Super node',
   swap179: 'Equity card has received share',
   swap180: 'National treasury',
   swap181: 'The accumulated amount of the treasury',
   swap182: 'Amount allocated by the treasury',
   swap183: 'Amount repurchased by the treasury',
   swap184: 'Subgraph Health Indicator',
   swap185: 'D6 Swap is the first equity tax system defi platform in the whole network based on okexchain. It gradually introduces swap, NFT, gamefi, lend, machine gun pool and other modules. Since its launch, it has provided a wider range of asset appreciation schemes and an unprecedented sense of experience through leading technology and fair and open market concept, which is deeply loved by the majority of users.',
   swap186: 'One-click take out',
   swap187: 'price impact too high',
   swap188: 'FAQ',
   swap189: 'NFT',
   swap190: 'Earn coins',
   swap191: 'In progress',
   swap192: 'Has ended',
   swap193: 'Benefit Card',
   swap193: 'Description',
   swap194: 'Ecological equity cards, limited to 1680 in the world',
   swap195: 'The requirements and benefits of lighting up the equity equity ecological card are as follows',
   swan196: 'One star card: the personal address has 1000usdt liquidity, the team has 1000usdt liquidity, and the income is divided into 0.1% of transaction tax',
   swap197: 'Two star card: the individual has 2000usdt liquidity and the team has 10000 usdt liquidity. The income is divided into 0.1% of transaction tax',
   swap198: 'Three star card: personal 3000usdt liquidity, team 100000 usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap199: 'Four star card: the individual has 4000usdt liquidity, the team has 1million usdt liquidity, set up a studio, and the income is divided into 0.1% of the transaction tax',
   swap200: 'Five star card: personal 5000usdt liquidity, team 10million usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap201: 'Note: all the benefits of the equity ecological card are automatically distributed on the chain. The equity card enjoys all the ecological dividends of the exchange. The equity card can be inherited, traded and transferred. If it meets the requirements, it will be automatically upgraded. If it does not meet the requirements, it will be automatically degraded and automatically withdrawn.',
   swap202: 'Benefit Card',
   swap203: 'development roadmap:',
   swap204: 'version 2.0 will be launched in July 2022 ',
   swap205: 'in September, 2022, it will be launched to dig more with coins',
   swap206: 'nft trading platform goes online in December 2022 ',
   swap207: 'goodbay game launched in June 2023 ',
   swap208: 'goodland launched in September 2023 ',
   swap209: 'D6 Swap introduction: ',
   swap210: 'sliding point:',
   swap211: 'allocation:',
   swap212: 'buy',
   swap213: 'sell',
   swap214: '0.5% LP dividend ',
   swap215: '0.5% ecological equity card dividend ',
   swap216: '0.25% reflux bottom pool destruction lp',
   swap217: '0.25% ecological construction ',
   swap218: 'No rewards to receive yet',
   swap219: 'Personal TVL',
   swap220: 'Team TVL',
   swap221: 'LP quantity',
   swap222: 'Pho in wallet',
   swap223: 'GDT',
   swap224: 'GDT air drop cumulative reward',
   swap225: 'Accumulative awards for star users',
   swap226: 'No fee countdown',
   swap227: 'Rewards can be withdrawn',
   swap228: 'LP mining reward',
   swap229: 'Pledge pho',
   swap230: 'Redemption of pho',
   swap231: 'target consensus',
   swap232: 'Consensus reached',
   swap233: 'Consensus',
   swap234: 'Consensus',
   swap235: 'Unlocked',
   swap236: 'Available',
   swap237: 'Community governance',
   swap238: 'Alliance consensus',
   swap239: 'Community Alliance',
   swap240: 'Pledge token',
   swap241: 'Pledging, please wait',
   swap242: 'transaction mining',
   swap243: 'New yesterday',
   swap244: 'Cumulative rewards for community whitelist',
   swap245: 'Cumulative rewards of equity star card',
   swap246: 'LP mining cumulative rewards',
   swap247: 'Currently available',
   swap248: 'Current recoverable amount',
   swap249: 'Trading mining',
   swap250: 'My share bonus accumulation',
   swap251: 'My transaction reward accumulation',
   swap252: 'Please enter your transaction hash',
   swap253: 'Check',
   swap254: 'Please enter your hash',
   swap255: 'Your transaction address',
   swap256: 'You will get',
   swap257: 'Only the transactions that buy GDT and create liquidity can be rewarded',
   swap258: 'Pledge gdt-pho',
   swap259: 'Redemption of gdt-pho',
   swap260: 'Total service charge of the whole network',
   swap261: 'Dividends to be claimed',
   swap262: 'GDT joint shareholders',
   swap263: 'intercourse',
   swap264: 'Mall',
   swap265: 'Selected Goods',
   swap266: 'Preferred store',
   swap267: 'Coming Soon',
   swap268: 'VGT joint shareholders',
   swap271: 'Recommended link',
   swap272: 'please bind leader',
   swap273: 'detailed',
   swap274: 'Metaverse',
   swap275: 'global',
   swap276: 'Exchange and liquidity',
   swap277: 'Trading、 Developing、 Making money、',
   swap278: 'Multi-chain aggregation DEX ecological platform',
   swap279: 'Enjoy the lowest transaction fees, profitable features and a multi-type referral program.',
   swap280: 'Route Map',
   swap281: 'Version 2.0 will be launched in July 2022',
   swap282: 'In September 2022, the currency holding and multi-mining will be opened',
   swap283: 'The NFT trading platform will be launched in December 2022',
   swap284: 'The Goodbaby game will be launched in June 2023',
   swap285: 'Goodlend will be launched in September 2023',
   swap286: 'Learn more',
   swap287: 'You have',
   swap288: 'In',
   swap289: 'Trading encrypted digital currency',
   swap290: 'Staking liquidity and tokens',
   swap291: 'Earn bonus rewards',
   swap292: 'launch pad',
   swap293: 'Authentication',
   swap294: 'Quality encrypted digital assets',
   swap295: 'Understand',
   swap296: 'Artwork',
   swap297: 'Trading market',
   swap298: 'Browse',
   swap299: 'Instant token exchange',
   swap300: 'Replicating Success',
   swap301: 'Copy not supported, this browser does not support automatic copy',
   swap302: 'Cumulative rewards for collaborative innovation',
   swap303: 'Accumulated sharing rewards',
   swap304: 'Accumulated transaction rewards',
   swap305: 'Asset package',
   swap306: 'Trading to earn coins',
   swap307: 'Withdrawable balance',
   swap308: 'Please enter the transaction hash',
   swap309: 'submit',
   swap310: 'Please enter a hash value',
   swap311: 'Successfully recorded',
   swap312: 'Binance',
   swap313: 'Platform',
   swap314: 'PHO zone',
   swap315: 'GDT zone',
   swap316: 'VGT zone',
   swap317: 'PHO star card',
   swap318: 'personal',
   swap319: 'Team',
   swap320: 'in the wallet',
   swap321: 'Select asset package',
   swap322: 'Please select',
   swap323: 'daily rate of return',
   swap324: 'income ratio',
   swap325: 'Total number due',
   swap326: 'Buy',
   swap327: 'Your wallet balance',
   swap328: 'My asset package',
   swap329: 'total income today',
   swap330: 'Today s static income',
   swap331: 'Today s dynamic income',
   swap332: 'accumulated income',
   swap333: 'expiration quantity',
   swap334: 'select',
   swap335: 'Cryptocurrency Zone',
   swap336: 'Purchase successful',
   swap337: 'Enter DApp',
   swap338: 'circle',
   swap339: 'Chat with your crypto circle buddies',
   swap340: 'candy box',
   swap341: 'A variety of high-quality asset airdrops',
   swap342: 'Come get it for free',
   swap343: 'crowd innovation pool',
   swap344: 'Mint Center',
   swap345: 'community circle',
   swap346: 'Candy box',
   swap347:'Earn',
swap348:'Airdrop',
swap349:'Sharing center',
swap350:'Affiliate program',
swap351:"Social contact",
swap352:"Bridge",
swap353:"Map",
swap354:"Water mining pool",
swap355:"Participate in the construction of mining pools and earn high incentives freely",
swap356:"Liquidity mining pool",
swap357:"Single currency pledge pool",
swap358:"Real time",
swap359:"Completed",
swap360:"Staked only",
swap361:"Sort by",
swap362:"Search",
swap363:" Popular ",
swap364:"Search pool",
swap365:"There is nothing here!",
swap366:"Startup template for the first Water Swap",
swap367:"Find the best matching path for each high-quality investor and encrypted asset",
swap368:"Create ISO",
swap369:"Become a Future Crypto Star",
swap370:"Coming soon",
swap371:"Please bind the recommender first",
swap372:"My inviter",
swap373:"Inviter's address:",
swap374:"",
swap375:"In Q3, D6 Swap was launched and launched, and Genesis coinage was launched, and the encrypted social platform CIRCLE was introduced at the same time to deeply link the ecological development of the community.",
swap376:"Q4 Inject liquidity into D6, start the “D6 Consensus Plan”, create a D6 circulation consensus, and activate the liquidity incentive plan. Launch the chain alliance cross-border mall, create a “consumption value-added” ecology, and promote a new direction for the combination of community and business.",
swap377:"",
swap378:"Q1 A package of alliance support plans to promote D6 as a basic liquidity support, increase market recognition and proportion, launch D-Pay, and promote the circulation of physical transactions of D6 Coin",
swap379:"Q2 officially launched D6 Trade, opening the era of CEX and DEX dual trading platforms, further improving the trading liquidity of the D6 market, and introducing new partners.",
swap380:"",
swap381:"",

d1:"Deposit",
d2:"Withdraw",
d3:"Transfer",
d4:"Bill",
d5:"Community",
d6:"Flash",
d7:"Feedback",
d8:"Casting",
d9:"Earn D6 Coins",
d10:"Earning up ",
d11:"Enter",
d12:"Water market",
d13:"Today's price",
d14:"Today's Earnings",
d15:"Check",
d16:"Assets",
d17:"Hide zero assets",
d18:"Currency",
d19:"Freeze",
d20:"Available",
d21:"Choose token",
d22:"Recharge amount",
d23:"Please enter the recharge amount",
d24:"Wallet balance:",
d25:"Account address",
d26:"Sure",
d27:"Recharge record",
d28:"Withdrawal amount",
d29:"Please enter the withdrawal quantity",
d30:"Account Balance:",
d31:"Arrival address",
d32:"Withdrawal record",
d33:"Transfer amount",
d34:"Please enter the transfer amount",
d35:"Enter the other party's address",
d36:"Please enter the recipient's address",
d37:"Transfer record",
d38:"Asset exchange",
d39:"To pay",
d40:"Get",
d41:"Quantity",
d42:"All",
d43:"Can be redeemed at most:",
d44:"Transaction tax:",
d45:"Due to account:",
d46:"Payment Quantity:",
d47:"Flash record",
d48:"Confirm exchange",
d49:"Flash record",
d50:"Earned",
d51:"Pending income",
d52:"Burn amount",
d53:"Please enter the burning amount",
d54:"In progress",
d55:"Over",
d56:"Circulation progress",
d57:"No records!",
d58:"Historical earnings",
d59:"My grade",
d60:"Current level",
d61:"After registration, you can become a D0 user",
d62:"Friend Invitation Program",
d63:"Invite friends to mint coins together, start the Water cognitive revolution, and accelerate rewards!",
d64:"Copy Link",
d65:"My recommender",
d66:"My valid recommendation",
d67:"Address",
d68:"Level",
d69:"Achievement",
d70:"Next level:",
d71:"Market performance",
d72:"Not completed",
d73:"Marketing department",
d74:"You are already at the highest level",
d75:"Announcement",
d76:"binding confirmation",
d77:"Leader Address:",
d78:"Confirm binding",
d79:"Cannot be negative and zero",
d80:"Insufficient balance",
d81:"Successful deposit",
d82:"Deposit failed",
d83:"Handling fee:",
d84:"Transfer in",
d85:"Transfer out",
d86:"Billing record",
d87:"Time",
d88:"Type",
d89:"Quantity",
d90:"Currency",
d91:"Please select the payment token first",
d92:"This token prohibits two-way exchange",
d93:"Cannot choose the same token",
d94:"Please bind the leader first",
d95:"Remaining burnable amount",
d96:"Please bind the recommender first",
d97:"Please fill in the recommender invitation code",
d98:"Latest Announcement",
d99:"Note:",
d100:"2: Withdrawal orders are expected to arrive on T+1 day",
d101:"1: Starting from  {min_withdraw} {token_name} for a single withdrawal",
d102:"3: 2 USDT tax is charged for each withdrawal",
d103:"Team Address",
d104:"Effective Team",
d105:"Top up today",
d106:"Pick up today",
d107:"Personal",
d108:"Team",
d109:"After it is turned on, the casting condition is met, and it will automatically participate in the casting",
d110:"It is expected to arrive within 1-2 minutes after recharging",
d111:"{feerate}% transaction tax charged for each transfer",
d112:"If you have any questions, please leave a question feedback!",
d113:"Feedback question",
d114:"Feedback list",
d115:"Feedback content",
d116:"Online customer service",
d117:"No reply yet",
d118:"Reply:",
d119:"1. Feedback",
d120:"2. Add pictures (up to 3)",
d121:"Submit",
d122:"Please upload images in png or jpg format",
d123:"Uploading, please wait...",
d124:"The file size cannot exceed 10M",
d125:"user",
d126:"CIRCLES AirDrop Mining Pool",
d127:"Total issuance",
d128:"Current output",
d129:"Extract",
d130:"My balance",
d131:"Check",
d132:"Number of people to share",
d133:"Working miner",
d134:"Hour",
d135:"Minute",
d136:"Second",
d137:"Mining",
d138:"Start mining",
d139:"Invite friends",
d140:"Friends start mining",
d141:"Get share revenue",
d142:"First level sharing",
d143:"Address",
d144:"State",
d145:"No more",
d146:"Secondary sharing",
d147:"Genesis Mining",
d148:"Enter",
d149:"CIRCLES is committed to the construction of an encrypted social privacy public chain. It is planned to be launched in 2025. The total number of public chain tokens is 2 billion. Each user can receive 1T of computing power, which will gradually decrease until a total of 80% is mined for free.",
d150:"Total asset valuation",
d151:"9·18 Pass",
d152: "The 9·18 Token is the first historical-themed community token cooperated by WaterSwap. With the goal of everyone holds it and will never forget it, we remember history and prevent dilution. Starting from the 9·18 Token! Now Get it for free!",
d153:"Mining Rules",
d154: "Each user can obtain 1T of computing power. Each T of computing power will produce 30 coins in the initial 24 hours. The computing power will decrease by 20% for every 10 million coins produced.",
d155: "50% direct invitation and 25% computing power bonus incentive for indirect invitation!",
d156: "Receive airdrop",
d157: "Withdrawal is not yet open",
d158: "When you bind the market leader, you can receive 9.18 918 token airdrops.",
d159: "If you participate in sharing, for every 10 users who share and receive airdrops, you will be eligible for another airdrop reward, up to a maximum of 918!",
d160:"My airdrop",
d161:"Inclusive Airdrop",
d162:"My sharing",
d163: "Withdrawal is not yet open",
d164:"Current level",
d165:"View earnings",
d166:"Ordinary user",
d167:"VIP{level} Member",
d168:"Upgrade",
d169:"Copy invitation code",
d170:"address",
d171:"status",
d172:"ecology",
d173:"Hold card",
d174: "Participate in ecological construction and receive generous rewards",
d175:"Can be collected today",
d176:"C2C trading market",
d177:"Current level",
d178:"Receive",
d179:"enter",
d180:"You do not have an invitation code",
d181:"My Market",
d182:"Cannot purchase",
d183:"Your leader is not a member",
d184: "Insufficient balance on the chain",
d185: "Insufficient platform balance",
d186: "Signature failed",
d187:"Transfer failed",
d188:"Renewal",
d189:"Invitation Code",
d190:"You need to pay",
d191:"Wallet Balance",
d192:"Balance",
d193:"Platform balance",
d194: "Payment in progress...please wait",
d195:"Payment successful",
d196:"Confirm payment",
d197:"Close",
d198:"Payment failed",
d199:"Transaction",
d200:"Earn coins",
d201:"Airdrop",
d202:"community",
d203:"Ecological Center",
d204:'Select network',
d205:"pieces",
d206:"Received",
d207:"Unaccalimed",





h1:'Homepage',
h2:'Mining Pool',
h3:'Transaction',
h4:'Launch Pad',
h5:'Partner',
h6:'Become a ONE DEX partner',
h7:'Enjoy the eight major ecological rights and interests, come and participate! ',
h8:'Enter',
h9:'currency',
h10:'Available amount',
h11:'Frozen amount',
h12:'on ONE DEX',
h13:'Trade cryptocurrencies',
h14:'Rules of participation',
h15:'Recruitment rules',
h16:'ONE DEX partner recruitment, plans to recruit a total of 200 people, and will be carried out in three phases. The relevant rules are as follows:',
h17:'Recruitment conditions:',
h18:'Payment during recruitment period',
h19:'Get rules:',
h20:'1: Sharing 5 valid partners will automatically upgrade to founding partners, first come first served, 200 seats are planned for founding partners: issue a HAPC token first, and map the HAPC token to HAP before going online',
h21: '30 in the first phase, 16 airdrops per person;',
h22: '60 in the second phase, 11 airdrops per person;',
h23: '110 in the third phase, 6 airdrops per person. ',
h24: 'If the situation is good, we can make an announcement to add N numbers in the fourth phase (plan) and airdrop n numbers per person',
h25:'2: Partners who recommend partners can enjoy 20% invitation reward',
h26:'3: Founding partners who invite partners can get 30% invitation incentive + 10% team unlimited generation invitation incentive (except for the same level)',
h27: '4. Without a partner invitation link, you cannot recharge to become a partner, nor can you recommend a partner',
h28:'5. System back-end addition: creation partner, partner switch settings. ',
h29:'Partner Recruitment Plan',
h30:'First issue',
h31:'Period {round}',
h32:'The third issue',
h33:'Recruitment progress',
h34:'Start time:',
h35:'End time:',
h36:'Recruit seats:',
h37:'Seat fee:',
h38:'Account balance',
h39:'Join',
h40:'Cannot join',
h41:'Latest Partner',
h42:'address',
h43:'Payment amount',
h44:'status',
h45:'Completed',
h46:'Signature failed',
h47:'ONE DEX Mining Pool',
h48: 'In the ONE DEX mining pool, freely earn high incentives',
h49:'The first ONE DEX startup template',
h50:'No information yet~',
h51:'New online',
h52:'Come to ONE DEX for trading',
h53:'High efficiency and multiple benefits',
h54:'Bridging Chain',
h55:'Ordinary user',
h56:'Partner',
h57:'Genesis Partners',
h58:'Problem feedback',
h59:'Airdrop activity',
h60:'NFT Center',
h61:'Ecological AI',
h62:'Inscription casting',
h63:'Partners rights',
h64:'1: To become a partner, your superior must also be a partner',
h65:'2: Partners who recommend partners can get 20% recommendation reward',
h66:'3: Directly share 5 partners, and can be automatically upgraded to "Creation Partner", with a maximum of 200 seats, until the end. ',
h67:'4: Creation partners who recommend partners can get 30% recommendation reward + 10% unlimited generation market reward (none for the same level). ',
h68:'5: Founding partners can obtain HAPC and map it to HAP before going online. The rules are as follows: ',
h69: '30 seats in the first phase, airdrop 15/seat;',
h70: 'The second phase of 60 seats, airdrop 10/seat;',
h71: 'There are 110 seats in the third phase, 5/seat will be airdropped. ',
h72:'6: Partners can receive 10 HAPB airdrop rights every day. ',
h73:'Warm reminder:',
h74:'1: Partner seats are limited and you can only participate during the recruitment period. ',
h75:'2: Before snapping up seats, you need to ensure that there is sufficient balance in your account and you need to transfer account assets. ',
h76:'Recruitment introduction',
h77:'Happyswap is a decentralized spot contract exchange that provides users with a safe, efficient, and optimal trading experience. Eliminate the impact of price and break the original platform monopoly and control. Users can conduct a variety of digital asset transactions and other digital asset derivative transactions on the HappySwap platform. HappySwap partners are issued in limited quantities globally. As a partner, you can not only enjoy the eight partner rights, but the profits of the entire platform are closely related to all partners. In the new era of digital assets, we will jointly create a new way of truly decentralized trading and share the ten thousandfold myth of digital finance.',
h78:'Remaining Seats',
h79:'Partner',
h80:'Creation',
h81:'Normal',
h82:'Partnership',
h83:'This issue has not been opened',
h84:'Join',
h85:'This term is full',
h86:'This issue has ended',
h87:'Deposit and withdrawal',
h88:'Transfer',
h89:'Transfer',
h90:'understand',
h91:'Entertainment games',
h92:'AI supercomputing',
h93:'FAQ',
h94:'Join',
h95: 'Get new opportunities in the pipeline, click to enter! ',
h96:'Experienced',
h97:'Our core members come from UNISWAP, MDEX, etc., and have rich experience in decentralized platforms! ',
h98:'Affiliate Program',
h99: 'Join the club, which provides the possibility of income under a variety of different mechanisms based on the same market network, and becomes your stable and reliable wealth partner!',
h100:'security',
h101: 'All contracts of ONE DEX are open source and fully audited. We will refuse to cooperate with tokens that are not open source and reviewed! ',
h102:'DAO co-construction',
h103:'ONE DEX is led by 49DAO, adhering to the community economic concept of "one center, N alliances", governing the community with the community, and fully realizing DAO governance. ',
h104:'Roadmap',
h105:'December 2023',
h106:'49DAO top-level partner preparation',
h107:'February 2024',
h108:'49DAO connects with the start-up community',
h109:'March 2024',
h110:'49DAO screening invitation list',
h111:'May 2024',
h112:'ONE DEX platform is online',
h113:'And open the Super ONE mining pool',
h114:'September 2024',
h115: 'Prepare and promote DEX ecological partnership',
h116: 'Partnership plan, release new DEX',
h117:'Development White Paper',
h118:'2025',
h119:'Promote the digital platform and high-quality partners',
h120:'Token is listed on many well-known CEX platforms',
h121:'Apply for listing',
h122:'User',
h123:'On ONE DEX',
h124:'Advantage',
h125:'expired',

o101:'Computing power to be produced',
o102:'Todays computing power income',
o103:'Share the profits today',
o104:'Bill',
o105:'View',
o106:'Personal effective energy',
o107:'Maximum community energy',
o108:'Other zone',
o109:'Dragon and Tiger List',
o110:'Energy value:',
o111:'Computing power value:',
o112:'Exchange',
o113:'Not open',
o114:'Produced:',
o115:'To be output:',
o116:'Output progress',
o117:'You need to pay',
o118:'Account balance',
o119:'Balance',
o120:'Confirm payment',
o121:'NFT store',
o122:'Producing',
o123:'Exhausted',
o124:'Invite friends',
o125:'Buy NFT',
o126:'Obtain shared benefits',
o127:'address',
o128:'Personal energy',
o129:'Team energy',
o130:'Computing power income',
o131:'Received',
o132:'To be collected',
o133:'Receive',
o134:'Share profits',
o135:'Dragon and Tiger List',
o136:'time',
o137:'Quantity',
o138:'Currency',
o139:'Computing power bill',
o140:'Share bill',
o141:'Dragon Tiger List Bill',
o142:'Exchange time',
o143:'Producing',
o144:'Exhausted',
o145:'NFT Center',

'待产出总值':'Total value to be produced',
'今日己产出':'Produced today',
'累计已产出':'Cumulative output',
'去交易':'Go trade',
'账户可用资产':'Account available assets',
'确定':'Sure',
'今日产出':'Todays output',
'团队总张数':'Total',
'最大社区数':'Max zone',
'原始值':'Original value',
'产出值':'Output value',
'激活':'Activation',
'已激活':'Activated',
'未激活':'Inactivated',
'到期时间':'Expire date',
'你需要销毁':'You need to destroy',
'确定销毁':'Confirm destruction',
'星石矿卡':'Star Stone Mine Card',
'工作中':'At work',
'已完成':'Completed',
'级别指南':'Level Guide',
'升级':'Upgrade',
'当前张数':'Current number of pictures',
'距离':'distance',
'需':'need',
'个人张数':'Number of individual sheets',
'团队张数':'Number of team sheets',
'普通':'Ordinary',
'R级':'R level',
'S级':'S level',
'SR级':'SR level',
'SSR级':'SSR level',
'UR级':'UR level',
'最大社区':'Largest community',
'其它社区':'Other communities',
'矿卡收益':'Mine card income',
'状态':'State',
'领取':'Receive',
'已到账':'Already arrived',
'已提取':'Extracted',
'矿卡产出':'Mine card output',
'布道津贴':'Preaching allowance',
'社区津贴':'Community allowance',
'孵化津贴':'Incubation allowance',
'提取记录':'Extract records',
'星级指南':'star guide',
'星级介绍':'Star introduction',
'根据团队当前持有有效的星石矿卡评定星级用户，分为5个等级':'Star users are rated according to the valid Star Stone Mine Card currently held by the team, which is divided into 5 levels.',
'星级权益':'Star benefits',
'可同时持有19张矿卡,并获得小业绩区新增业绩1%的加速！':'You can hold 19 mining cards at the same time and get a 1% acceleration in new performance in the small performance area!',
'可同时持有29张矿卡,并获得小业绩区新增业绩3%的加速！':'You can hold 29 mining cards at the same time, and get a 3% acceleration in new performance in the small performance area!',
'可同时持有39张矿卡,并获得小业绩区新增业绩5%的加速!在出现平级时可获得50%平级收入孵化津贴！':'You can hold 39 mining cards at the same time, and get a 5% acceleration in new performance in the small performance area! When a tier occurs, you can get 50% of the tier income incubation allowance!',
'可同时持有49张矿卡,并获得小业绩区新增业绩7%的加速!在出现平级时可获得40%平级收入孵化津贴！':'You can hold 49 mining cards at the same time, and get a 7% acceleration in new performance in the small performance area! When a tier occurs, you can get a 40% tier income incubation allowance!',
'可同时持有49张矿卡,并获得小业绩区新增业绩9%的加速!在出现平级时可获得30%平级收入孵化津贴！':'You can hold 49 mining cards at the same time, and get a 9% acceleration in new performance in the small performance area! When a tier occurs, you can get a 30% tier income incubation allowance!',
'当团队持有矿卡到期后，有效张数失效，级别存在下降可能！':' When the mining card held by the team expires, the number of valid cards will expire and the level may drop!',
'买单':'Buy',
'卖单':'Sell',
'我的订单':'My Order',
'请输入最小价格':'Please enter minimum price',
'请输入最大价格':'Please enter maximum price',
'最小价格：':'Minimum price:',
'最大价格：':'Maximum price:',
'取消':'Cancel',
'清除价格':'clear price',
'选择发布时间':'Select publishing time',
'清除时间':'Clear time',
'发布时间：':'release time:',
'数量：':'quantity:',
'单价':'unit price',
'支付方式：':'payment method:',
'卖出':'sell',
'买入':'Buy',
'最新成交价':'Latest transaction price',
'发布买单':'Post a buy order',
'发布卖单':'Post a sell order',
'选择年月日时':'Select year, month, day and time',
'购买数量':'Purchase quantity',
'卖出数量':'Sell ​​quantity',
'购买单价':'Purchase unit price',
'卖出单价':'Selling price',
'请输入购买数量':'Please enter the purchase quantity',
'请输入卖出数量':'Please enter the selling quantity',
'请输入购买单价':'Please enter the purchase unit price',
'请输入卖出单价':'Please enter the selling price',
'购买总价':'Total purchase price',
'卖出总价':'Total selling price',
'账户余额':'Account Balance',
'说明：交易星石收取税收手续费，买入星石收取':'Note: Tax fees are charged for trading star stones, and tax fees are charged for buying star stones.',
'税收，卖出星石收取':'Taxes, collected from selling star stones',
'税收':'tax',
'全部':'all',
'当前剩余可交易数量：':'Current remaining tradable quantity:',
'个人账户数量余额：':'Personal account balance:',
'个人账户数量余额：':'Personal account balance:',
'可得：':'Available:',
'需支付':'Need to pay',
'购买价格':'price',
'发布时间':'release time',
'自动计算':'Automatic calculation',
'请选择发布时间区间':'Please select a release time range',
'请选择正确的发布时间区间':'Please select the correct release time interval',
'请输入最小最大价格':'Please enter the minimum and maximum price',
'请输入正确价格':'Please enter the correct price',
'请输入数量和价格':'Please enter quantity and price',
'取消订单':'cancel order',
'已完成':'completed',
'已取消':'Cancelled',
'订单号':'order number',
'星石单价':'Star stone unit price',
'星石总量':'Total amount of star stones',
'星石总价':'Total price of star stones',
'已完成数量':'Completed quantity',
'可撤销数量':'Undoable quantity',
'发布时间':'release time',
'暂无订单':'No orders yet',
'发布中':'Announcing',
'已完成':'Completed',
'已取消':'Cancelled',
'星石矿池':'REAL  A',
'铸造星石':'Forged Star Stone',
'探索无限':'Explore unlimited',
'首发RWA星石矿池NFT！':'The first release of RWA Star Stone Pool NFT!',

'专属NFT':'Exclusive NFT',
'持有张数':'Number of sheets held',
'张':'open',
'所属系列':'Series',
'挂卖':'For sale',
'转赠':'transfer',
'NFT系列':'NFT series',
'加入我们的社区':'Join our community',
'核心价值观影响着社区的文化，并决定前进的道路。如何听取更多的声音，及时有效的向所有社区成员传达讯息，显得极为重要，在未来进行治理模型时，既要着眼于未来，亦要注重当前的需求，兼顾个人和集体，不断提高，我们乐于与您共同参与到未来的建设！':'Core values ​​influence the culture of a community and determine the path forward. How to listen to more voices and convey information to all community members in a timely and effective manner is extremely important. When developing governance models in the future, we must not only focus on the future, but also pay attention to current needs, take into account both individuals and collectives, and continuously improve. We We are happy to participate in the construction of the future with you!',
'加入社区':'Join the community',
'已加入社区':'Joined the community',
'市场':'market',
'转赠':'transfer',
'矿池':'Mining pool',
'记录':'Record',
'暂未开放':'Not open yet',
'其他说明':'Other instructions',
'可交易':'Tradable',
'交易中':'Transaction',
'类型':'Type',
'已划转':'Transferred',
'划转':'Transfer',
'待划转':'To be transferred',
'划转记录':'Transfer record',
'待产出星石':'Star stone to be produced',
'我的邀请码':'My invitation code',
'复制邀请码':'Copy invitation code',
'我的邀请人':'My inviter',
'填写邀请码':'Fill in the invitation code',
'请输入邀请码':'Please enter the invitation code',
'确认':'Confirm',
'矿池':'Mining pool',
'集市':'Market',
'社区':'Community',
'请先绑定推荐人':'Please bind the recommender first',
'请填写推荐人地址':'Please fill in the recommenders address',
'今日总产出':'Total output today',
'账户可用星石':'Account available star stones',
'我的产出':'My production',
'累计已领':'Claimed',
'今日待领':'Today claim',

'权益中心':'Equity Center',
'提案公投':'Proposal referendum',
'席位招募':'Seat recruitment',
'治理中心':'Governance Center',
'董事竞选':'Director election',
'竞选成为管理委员会':'Run for the Governing Council',
'董事':'director',
'发起提案':'Initiate proposal',
'发起社区发展提案':'Initiate community development proposals',
'投票决议':'voting resolution',
'参与提案投票表决':'Participate in voting on proposals',
'获得激励':'get motivated',
'获得税收分红激励':'Get tax dividend incentives',
'节点激励':'Node incentives',
'已领取':'Received',
'待领取':'To be collected',
'节点账单':'Node bill',
'时间':'Time',
'数量':'Quantity',
'来源':'Source',
'暂无数据':'No data yet',
'领取成功':'Received successfully',
'提案公投':'Proposal referendum',
'发起治理提案，并获得不少于10位董事支持后':'After initiating a governance proposal and obtaining the support of no less than 10 directors',
'则将进行下一步公投':'The next referendum will be held',
'提交提案':'Submit proposal',
'进行中':'In progress',
'即将开始':'About to start',
'已结束':'Ended',
'搜索':'Search',
'去投票':'Go vote'
};
